<script lang="ts">
	import Header from '$lib/components/header.svelte';
	import { goto } from '$app/navigation';
	import { onMount } from 'svelte';
	import { config } from '$lib/app-config';

	onMount(() => {
		goto(`/${config.fallbackLocalization.locale}/profile-machinings/`);
	});
</script>

<Header title="Manufacturing" />
